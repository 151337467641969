import {useState} from "react";
import '../styles/form.css';
import useFetch from "../custom_hooks/useFetch";

const CreatePump = () => {
    const [inputs, setInputs] = useState({brand: 1, images: "", downloads: "", name: ""});
    const [fileList, setFileList] = useState([]);
    const [downloadList, setDownloadList] = useState([]);
    const files = fileList ? [...fileList] : [];
    const downloads = downloadList ? [...downloadList] : [];

    const [categories] = useFetch("https://www.rs-pompen.eu/categories");
    const [brands] = useFetch("https://www.rs-pompen.eu/brands");


    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const handleMultiSelectChange = (event) => {
        const name = event.target.name;
        const value = Array.from(event.target.selectedOptions, option => option.value);
        setInputs(values => ({...values, [name]: value}))
    }

    const handleFileChange = (event) => {
        console.log("FILE Change");
        setFileList(event.target.files);
    };

    const handleDownloadsChange = (event) => {
        console.log("DOWNLOAD Change");
        setDownloadList(event.target.files);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const imagesFormData = new FormData();
        const downloadsFormData = new FormData();

        files.forEach((file, i) => {
            console.log(file);
            const fileName = new Date().toISOString() + "-" + inputs.name + "-" + file.name;
            if (inputs.images === "") inputs.images = "/images/pumps/" + fileName;
            else inputs.images += "\n/images/pumps/" + fileName;
            imagesFormData.append(`file-${i}`, file, fileName);
        });

        downloads.forEach((file, i) => {
            console.log(file);
            const fileName = new Date().toISOString() + "-" + inputs.name + "-" + file.name;
            if (inputs.downloads === "") inputs.downloads = "/downloads/pumps/" + fileName;
            else inputs.downloads += "\n/downloads/pumps/" + fileName;
            downloadsFormData.append(`file-${i}`, file, fileName);
        });

        console.log(inputs);

        //return;

        if (downloads.length > 0) {
            fetch('https://www.rs-pompen.eu/products/downloads', {
                method: 'POST',
                body: downloadsFormData
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    const status = data.status;
                    // Files are successfully uploaded.
                    if (status === "OK") {
                        fetch('https://www.rs-pompen.eu/products/upload', {
                            method: 'POST',
                            body: imagesFormData
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                console.log(data);
                                const status = data.status;
                                // Files are successfully uploaded.
                                if (status === "OK") {
                                    fetch('https://www.rs-pompen.eu/products/add', {
                                        method: 'POST',
                                        body: JSON.stringify({
                                            data: inputs
                                        }),
                                        headers: {
                                            'Content-type': 'application/json; charset=UTF-8',
                                        },
                                    })
                                        .then((response) => response.json())
                                        .then((data) => {
                                            console.log(data);
                                            // Clear the array.
                                            inputs.images = "";
                                            inputs.downloads = "";
                                            // Handle data
                                            alert(`De pomp met de naam '${inputs.name}' is succesvol toegevoegd!`);
                                        })
                                        .catch((err) => {
                                            console.log(err.message);
                                        });
                                } else alert("Error de bestanden kunnen niet geupload worden!")
                            })
                            .catch((err) => {
                                console.log(err.message);
                            });
                    } else alert("Error de 'download'-bestanden kunnen niet geupload worden!")
                })
                .catch((err) => {
                    console.log(err.message);
                });
        } else {
            fetch('https://www.rs-pompen.eu/products/upload', {
                method: 'POST',
                body: imagesFormData
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    const status = data.status;
                    // Files are successfully uploaded.
                    if (status === "OK") {
                        fetch('https://www.rs-pompen.eu/products/add', {
                            method: 'POST',
                            body: JSON.stringify({
                                data: inputs
                            }),
                            headers: {
                                'Content-type': 'application/json; charset=UTF-8',
                            },
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                console.log(data);
                                // Clear the array.
                                inputs.images = "";
                                // Handle data
                                alert(`De pomp met de naam '${inputs.name}' is succesvol toegevoegd!`);
                            })
                            .catch((err) => {
                                console.log(err.message);
                            });
                    } else alert("Error de bestanden kunnen niet geupload worden!")
                })
                .catch((err) => {
                    console.log(err.message);
                });
        }
    }

    return (
        <>
            <div className={"content"}>
                <div className={"container"} style={{backgroundColor: "var(--main-background-color)", paddingTop: "10px", paddingBottom: "10px"}}>
                    <h1 style={{color: "white"}}>Eén nieuw product toevoegen</h1>
                </div>
                <div className={"container"}>
                    <div>
                        <form className={"description form"} onSubmit={handleSubmit}>
                            <h1>Standaard gegevens</h1>
                            <div className="form-field">
                                <label>Naam</label><input name="name" onChange={handleChange} required/>
                            </div>
                            <div className="form-field">
                                <label>Merk</label>
                                <select name={"brand"} onChange={handleChange}>
                                    {
                                        brands && brands.data.brands.map((item) => (
                                            <option value={item.ID}>{item.name}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="form-field">
                                <label style={{backgroundColor: "white"}}>Categorieen</label>
                                <select name={"categories"} onChange={handleMultiSelectChange} multiple required style={{minHeight: "200px"}}>
                                    {
                                        categories && categories.data.categories.map((item) => (
                                            <option value={item.ID}>{item.name}</option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div className="form-field">
                                <label>Subtekst</label><input name="info" onChange={handleChange}/>
                            </div>
                            <div className="form-field">
                                <label>Extra</label><input name="extra" onChange={handleChange}/>
                            </div>
                            <div className="form-field">
                                <label>Omschrijving</label><textarea name="description" onChange={handleChange}/>
                            </div>
                            <hr/>
                            <h1>Bestanden</h1>
                            <div className={"form-field"}>
                                <label>Media</label><input type="file" id="files" accept="image/*" name="files" onChange={handleFileChange} multiple required/>
                            </div>
                            <div className={"form-field"}>
                                <label>Downloads</label><input type="file" id="downloads" accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xlsx,.zip,.rar" name="files" onChange={handleDownloadsChange} multiple/>
                            </div>
                            <div className="form-field" style={{display: "none"}}>
                                <label>URLs</label><textarea name="images" onChange={handleChange}/>
                            </div>
                            <hr/>
                            <h1>Info</h1>
                            <div className="form-field">
                                <label>Specificaties</label><textarea name="specs" onChange={handleChange}/>
                            </div>
                            <div className="form-field">
                                <label>Technisch</label><textarea name="technical" onChange={handleChange}/>
                            </div>
                            <div className="form-field">
                                <label>Toepassingen</label><textarea name="applications" onChange={handleChange}/>
                            </div>
                            <div className="form-field">
                                <label>Opties</label><textarea name="options" onChange={handleChange}/>
                            </div>
                            <button type="submit">TOEVOEGEN</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
};

export default CreatePump;