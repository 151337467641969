import {useState} from "react";

const Contact = () => {
    const [inputs, setInputs] = useState({company: "", contact: "", email: "", telephone: "", request: ""});

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        console.log(inputs);

        fetch('https://rs-pompen.eu/contact', {
            method: 'POST',
            body: JSON.stringify({
                data: inputs
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                alert("Uw verzoek is ingediend!");
            });
    }

    return (
        <>
            <div className={"content"}>
                <div className={"container"} style={{backgroundColor: "var(--main-background-color)", paddingTop: "10px", paddingBottom: "10px"}}>
                    <h1 style={{color: "white"}}>Contact</h1>
                </div>
                <iframe title={"location"}
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2457.0877035204758!2d6.052862815862324!3d51.98705508333972!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c7a36cb3538b23%3A0x7171da2a7d1d15cf!2sRS-POMPEN!5e0!3m2!1sen!2snl!4v1672831030468!5m2!1sen!2snl"
                    height="400" style={{border: 0, width: "100%"}} allowFullScreen="" loading="lazy"/>
                <div className={"container"}>
                    <div>
                        <div className={"panel-row-flex black f2"} style={{flexWrap: "wrap"}}>
                            <div>
                                <h1 style={{textAlign: "left"}}>Contact</h1>
                                <p style={{textAlign: "left"}}>
                                    <strong>RS Pompen</strong><br/>
                                    Onderdeel van RS machinebouw<br/><br/>
                                    Uitmaat 10b<br/>
                                    6987 ER Giesbeek<br/>
                                    +31 (0)85 06 08 250<br/>
                                    <a href="mailto:sales@rs-pompen.eu">info@rs-pompen.eu</a>
                                </p>
                            </div>
                            <div>
                                <h1 style={{textAlign: "left"}}>Contact formulier</h1>
                                <form className={"description form"} onSubmit={handleSubmit}>
                                    <div className={"contact"}>
                                        <label>Bedrijfsnaam</label>
                                        <input name={"company"} type={"text"} onChange={handleChange}/>
                                        <label>Contactpersoon <sup>*</sup></label>
                                        <input name={"contact"} type={"text"} required onChange={handleChange}/>
                                        <label>E-mail <sup>*</sup></label>
                                        <input name={"email"} type={"email"} required onChange={handleChange}/>
                                        <label>Telefoonnummer</label>
                                        <input name={"telephone"} type={"tel"} onChange={handleChange}/>
                                        <label>Vraag of verzoek <sup>*</sup></label>
                                        <textarea name={"request"} required onChange={handleChange}/>
                                    </div>
                                    <p style={{paddingBottom: "15px"}}>
                                        <button type="submit" style={{border: "none"}} className={"button_contact div_center"}>Verstuur</button>
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Contact;