import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from './reportWebVitals';

import './styles/default.css';

import Body from "./pages/Body";

import Main from "./pages/Main";
import Service from "./pages/Service";
import Engineering from "./pages/Engineering";
import Toepassingen from "./pages/Toepassingen";
import Contact from "./pages/Contact";
import PumpCategories from "./pages/PumpCategories";
import Pumps from "./pages/Pumps";
import Pump from "./pages/Pump";
import CreatePump from "./pages/CreatePump";

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/*" element={<Body/>}>
                    <Route index path="*" element={<Main/>}/>
                    <Route path="service" element={<Service/>}/>
                    <Route path="engineering" element={<Engineering/>}/>
                    <Route path="toepassingen" element={<Toepassingen/>}/>
                    <Route path="contact" element={<Contact/>}/>
                    <Route path="new" element={<CreatePump/>}/>
                    <Route path="pompen/*">
                        <Route index path="" element={<PumpCategories/>}/>
                        <Route path={":category"} element={<Pumps/>}/>

                        <Route path={":category/:pump"} element={<Pump/>}/>
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App/>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
