import {Link, useParams} from "react-router-dom";
import useFetch from "../custom_hooks/useFetch";

const Pumps = () => {
    const { category } = useParams();
    // Request the pumps form the API.
    const [data] = useFetch(`https://www.rs-pompen.eu/categories/${category}`);

    return (
        <>
            <div className={"content"}>
                <div className={"container"} style={{backgroundColor: "var(--main-background-color)", paddingTop: "10px", paddingBottom: "10px"}}>
                    <h1 style={{color: "white"}}>{category}</h1>
                </div>
                <div className={"container"}>
                    <div>
                        <div className={"panel-row-flex black f4 items"} style={{flexWrap: "wrap", justifyContent: "unset"}}>
                            {
                                data && data.status === "OK" ? (
                                    data.data.products.map((item) => (
                                        <div>
                                            <Link className={"product"} to={item.name}>
                                                <h1>{item.name}</h1>
                                                <div style={{display: "block", height: "189px"}}>
                                                {
                                                    item.config.images ? <img src={item.config.images[0]} style={{maxHeight: "100%", maxWidth: "100%"}} alt={""}/> :<></>
                                                }
                                                </div>
                                                <p>
                                                    <strong>{item.config.info}</strong>
                                                    <br/>
                                                    <br/>
                                                    <strong>{item.config.extra}</strong>
                                                    <br/>
                                                    <br/>
                                                    <ul>
                                                        {item.config.specs && item.config.specs.map((specs, index) => (
                                                            <>
                                                                {index < 3 ? (
                                                                    <li>
                                                                        {specs}
                                                                    </li>) : <></>
                                                                }
                                                            </>
                                                        ))}
                                                    </ul>
                                                </p>
                                            </Link>
                                        </div>
                                    ))
                                ) : (<h1>
                                    Excuses, er kunnen geen producten gevonden worden in de category '{category}'!
                                </h1>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Pumps;