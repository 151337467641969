import engineering from "../../src/resources/engineering.png"
import meetsysteem_online from "../../src/resources/meetsysteem_online.png"
import krzywkowa3IMG from "../../src/resources/meetsysteem_online.png"

const Engineering = () => {
    return (
        <>
            <div className={"content"}>
                <div className={"container"} style={{backgroundColor: "var(--main-background-color)", paddingTop: "10px", paddingBottom: "10px"}}>
                    <h1 style={{color: "white"}}>Engineering en 3D ontwerp</h1>
                </div>
                <div className={"container"}>
                    <div>
                        <div className={"panel-row-flex f3 black"}>
                            <div>
                                <img src={engineering} alt={""}/>
                                <strong>Engineering</strong>
                            </div>
                            <div>
                                <img src={meetsysteem_online} alt={""}/>
                                <strong>Monitoring</strong>
                            </div>
                            <div>
                                <img src={krzywkowa3IMG} alt={""} style={{width: "67%"}}/>
                                <strong style={{float: "left"}}>3D ontwerp</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"container container_blue"}>
                    <div>
                        <p style={{textAlign: "left"}}>
                            <strong>RS Pompen</strong> bied u engineering op maat. Of het nu een elektrisch of diesel aangedreven pomp installatie betreft, wij bespreken uw specifieke wensen en werken dit uit tot een innovatieve hoogwaardige pomp oplossing. Hierbij wordt rekening gehouden met de duurzaamheid van het product, de betrouwbaarheid en het snel en eenvoudig onderhoud van de pomp installatie.
                        </p>
                        <p style={{textAlign: "left"}}>
                            Ook voor engineering van uw nieuwe of bestaande besturing bent u bij ons aan het juiste adres. Wij denken graag samen met de klant mee naar de juiste (duurzame) oplossing. U kunt uw pomp installatie verduurzamen door de pompen door frequentieregeling te laten aansturen. Hierdoor kunt u aanzienlijk besparen op de energiekosten.
                        </p>
                        <p style={{textAlign: "left"}}>
                            Wilt u uw (pomp) proces inzichtelijk maken, dan bieden wij u online monitoring aan. Hierbij maken wij uw installatie gereed voor industrie 4.0. Het is mogelijk om de metingen 24/7 online te monitoren in het OEE dashboard. Het OEE dashboard is een online omgeving waar het machinerendement staat weergegeven. Afwijkingen zijn hierin direct zichtbaar. Van eventuele storingen krijgt u een seintje via sms of e-mail. U kunt ook meldingen ontvangen wanneer er bijvoorbeeld slijtage optreedt in de pomp, als de pomp veel vermogen opneemt. Of juist vermogen afneemt bij eventuele verstoppingen in de persleiding.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Engineering;