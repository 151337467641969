import {Link} from "react-router-dom";
import useFetch from "../custom_hooks/useFetch";

const PumpCategories = () => {
    // Request the categories form the API.
    const [data] = useFetch("https://www.rs-pompen.eu/categories");

    return (
        <>
            <div className={"content"}>
                <div className={"container"} style={{backgroundColor: "var(--main-background-color)", paddingTop: "10px", paddingBottom: "10px"}}>
                    <h1 style={{color: "white"}}>Pompen</h1>
                </div>
                <div className={"container"}>
                    <div>
                        <div className={"panel-row-flex black f2 items"} style={{flexWrap: "wrap"}}>
                            {data && data.data.categories.map((item) => (
                                <div>
                                    <Link className={"product"} to={item.name}>
                                        <h1>{item.name}</h1>
                                        <img src={item.image} alt={""}/>
                                    </Link>
                                </div>)
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default PumpCategories;