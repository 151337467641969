import pompen_voor_industrie from '../resources/pompen_voor_industrie-1.png';
import pompen_voor_landbouw from '../resources/pompen_voor_landbouw.png';
import pompen_voor_utiliteit from '../resources/pompen_voor_utiliteit.png';
import pompen_voor_beregening from '../resources/pompen_voor_beregening.png';
import pompen_voor_grafische_industrie from '../resources/pompen_voor_grafische_industrie.png';
import pompen_voor_aannemerij from '../resources/pompen_voor_aannemerij.png';

import engineering_pompen from '../resources/engineering_pompen.png';

import icon_pomp_techniek from '../resources/icon_pomp_techniek.png';
import icon_pomp_revisie from '../resources/icon_pomp_revisie.png';
import icon_montage from '../resources/icon_montage (1).png';
import icon_pomp_disc from '../resources/icon_pomp_disc (1).png';
import icon_engineering from '../resources/icon_engineering (1).png';
import icon_innovatie from '../resources/icon_innovatie.png';
import icon_online_monitoring from '../resources/icon_online_monitoring.png';
// Strong brands.
import AEONPUMP from '../resources/strongBrands/AEONPUMP.png';
import GMP_LOGO from '../resources/strongBrands/GMP_LOGO_e1667906164237.jpg';
import AFpumps from '../resources/strongBrands/LOGO-AFpumps-e1667906250973.png';
import Debem from '../resources/strongBrands/LOGO-Debem-pumps-e1667906127907.png';
import Euromacchine from '../resources/strongBrands/LOGO-Euromacchine.png';
import Flussmann from '../resources/strongBrands/LOGO-Flussmann-e1667906238840.png';
import JSpumps from '../resources/strongBrands/LOGO-JSpumps-2-e1667906194809.png';
import Koshin from '../resources/strongBrands/LOGO-Koshin-e1667906202913.png';
import LESA from '../resources/strongBrands/LOGO-LESA-messtechnik-1-e1667906058335 (1).png';
import PM from '../resources/strongBrands/LOGO-PM-technology-e1667906154801.png';
import SAER from '../resources/strongBrands/LOGO-SAER-e1667906220741.png';
import Solidpump from '../resources/strongBrands/LOGO-Solidpump-e1667906086306.png';
import Zirantec from '../resources/strongBrands/LOGO-Zirantec-e1667906070769.png';

import {Link} from "react-router-dom";

const Main = () => {
    return (
        <>
            <div className={"content"}>
                <div className={"panel-row"}>
                    <div>
                        <div className={"panel-row-flex f7"}>
                            <div>
                                <img src={pompen_voor_industrie} alt={""}/>
                                <strong>Industrie & machinebouw</strong>
                            </div>
                            <div>
                                <img src={pompen_voor_landbouw} alt={""}/>
                                <strong>Landbouw</strong>
                            </div>
                            <div>
                                <img src={pompen_voor_utiliteit} alt={""}/>
                                <strong>Utiliteit</strong>
                            </div>
                            <div>
                                <img src={pompen_voor_beregening} alt={""}/>
                                <strong>Glastuinbouw & beregening</strong>
                            </div>
                            <div>
                                <img src={pompen_voor_grafische_industrie} alt={""}/>
                                <strong>Grafische & papier industrie</strong>
                            </div>
                            <div>
                                <img src={pompen_voor_aannemerij} alt={""}/>
                                <strong>Bouwnijverheid</strong>
                            </div>
                            <div>
                                <img src={engineering_pompen} alt={""}/>
                                <strong>Engineering &
                                    3D ontwerp</strong>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"container container_blue"}>
                    <div>
                        <h1>Ons bedrijfsprofiel</h1>
                        <p>
                            <strong>RS Pompen</strong> is een jong en dynamisch bedrijf uit Nederland. <strong>RS Pompen</strong> is onderdeel van RS-Machinebouw B.V. Wij zijn continue opzoek naar uitdagingen / opportunity’s. <strong>RS Pompen</strong> is gespecialiseerd in leveren van pompen, pomp systemen, mixers en appendages. Vanuit onze locatie in Giesbeek werkt een team ervaren pomp specialisten. Wij bieden u deskundig advies en klant gerichte oplossingen met onze expertise, creativiteit en persoonlijke aandacht.
                        </p>
                        <div className={"panel-row-flex f3"}>
                            <div className={"container_white"}>
                                <h1>Onze kenmerken</h1>
                                <p>
                                    Met onze expertise bieden wij u service en advies op maat. Wij verzorgen het ontwerp, installatie, inbedrijfstelling, service en het onderhoud van uw pomp installatie.
                                </p>
                            </div>
                            <div className={"container_white"}>
                                <h1>Korte lijnen, persoonlijke aandacht</h1>
                                <p>
                                    Geen grote logge organisatie, maar directe communicatie. <strong>RS Pompen</strong> is geen grootschalig bedrijf. De voordelen hiervan zijn dat de klanten de wensen direct met onze specialisten bespreken, waardoor u voorzien wordt van een eerlijk en passend advies.
                                </p>
                            </div>
                            <div className={"container_white"}>
                                <h1>Duurzaamheid</h1>
                                <p>
                                    Wij zijn innovatief en continue op zoek naar kwalitatieve en duurzame oplossingen. Uitsluitend gebruik van betrouwbare producten van hoogwaardige kwaliteit resulteert in een optimale levensduur van uw pomp installatie. Duurzaamheid sluit aan op onze visie en willen wij dan ook graag uitdragen naar onze klanten.
                                </p>
                            </div>
                        </div>
                    </div>
                    <p style={{paddingBottom: "15px"}}>
                        <Link to={"/contact"} className={"button_contact div_center"}>Neem contact met ons op</Link>
                    </p>
                </div>

                <div className={"container container_white"}>
                    <div>
                        <h1>Welke diensten verlenen wij?</h1>
                        <table className={"images"}>
                            <tbody>
                                <tr><td><img src={icon_pomp_techniek} alt={""}/></td><td>Pomp techniek verkoop, service, advies en onderhoud</td></tr>
                                <tr><td><img src={icon_pomp_revisie} alt={""}/></td><td>Revisie van A tot Z</td></tr>
                                <tr><td><img src={icon_montage} alt={""}/></td><td>Montage en inbedrijfstelling</td></tr>
                                <tr><td><img src={icon_pomp_disc} alt={""}/></td><td>Pomp besturingen op maat</td></tr>
                                <tr><td><img src={icon_engineering} alt={""}/></td><td>Engineering en 3D ontwerp,pomp opbouw en aanpassing aan klant specifieke wensen</td></tr>
                                <tr><td><img src={icon_innovatie} alt={""}/></td><td>Innovatieve technieken toepassen</td></tr>
                                <tr><td><img src={icon_online_monitoring} alt={""}/></td><td>Online monitoring Industrie 4.0 op bestaande en nieuwe pomp systemen</td></tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className={"container"} style={{backgroundColor: "var(--main-background-color)", paddingTop: "10px", paddingBottom: "10px"}}>
                    <h1 style={{color: "white"}}>Producten</h1>
                </div>
                <div className={"container container_white"}>
                    <div>
                        <p style={{textAlign: "left"}}>
                            <strong>RS Pompen</strong> is gespecialiseerd in leveren van pompen, pomp systemen, mixers en appendages.  Wij leveren uitsluitend kwaliteitsproducten. Hierdoor kunnen wij een goede bedrijfszekerheid garanderen. Wij zijn niet merk gebonden, waardoor wij onze producten en diensten breed kunnen uitzetten.
                        </p>
                        <p style={{textAlign: "left"}}>
                            <strong>RS Pompen</strong> levert onder andere onderstaande pomp principes:
                        </p>
                        <ul>
                            <li>Centrifugaal pompen</li>
                            <li>Dompel pompen</li>
                            <li>Vuilwater pompen</li>
                            <li>Bron pompen en motoren</li>
                            <li>Verticale in-line pompen</li>
                            <li>Verticale meertraps pompen</li>
                            <li>Horizontale meertraps pompen</li>
                            <li>Splitcase pompen</li>
                            <li>Periferaal pompen</li>
                            <li>Verdringer pompen</li>
                            <li>mixers</li>
                            <li>Solar pompen en toebehoren</li>
                        </ul>
                        <h1>Sterke merken</h1>
                        <table className={"images brands"}>
                            <tbody>
                                <tr><td><img src={AEONPUMP} alt={""}/></td><td><img src={GMP_LOGO} alt={""}/></td><td><img src={AFpumps} alt={""}/></td></tr>
                                <tr><td><img src={Debem} alt={""}/></td><td><img src={Euromacchine} alt={""}/></td><td><img src={Flussmann} alt={""}/></td></tr>
                                <tr><td><img src={JSpumps} alt={""}/></td><td><img src={Koshin} alt={""}/></td><td><img src={LESA} alt={""}/></td></tr>
                                <tr><td><img src={PM} alt={""}/></td><td><img src={SAER} alt={""}/></td><td><img src={Solidpump} alt={""}/></td></tr>
                            <tr><td></td><td><img src={Zirantec} alt={""}/></td><td></td></tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={"container"} style={{backgroundColor: "var(--main-background-color)", paddingTop: "10px", paddingBottom: "10px"}}>
                    <h1 style={{color: "white"}}>Service en pompen</h1>
                </div>
                <div className={"container container_blue"}>
                    <div>
                        <p style={{textAlign: "left"}}>
                            <strong>RS Pompen</strong> verleent service en onderhoud aan alle merken pompen, en is daardoor niet merk gebonden. Vanuit onze vestiging in Giesbeek worden de reparatie en revisie werkzaamheden uitgevoerd.
                        </p>
                        <p style={{textAlign: "left"}}>
                            Onze service engineers kunnen uw pompen voorzien van het juiste onderhoud op locatie of in onze werkplaats. Door te investeren in periodiek onderhoud, voorkom u problemen en storingen. Zo bespaard u op energie en gebruikskosten, en verlengt u de levensduur en kwaliteit van uw pomp installatie.
                        </p>
                        <Link to={"/service"}>Lees meer></Link>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Main;