import {Link, useParams} from "react-router-dom";
import useFetch from "../custom_hooks/useFetch";
import {useEffect} from "react";

import UNKNOWN from '../resources/icons/unknown-document-svgrepo-com.svg';
import EXCEL from '../resources/icons/excel-document-svgrepo-com.svg';
import CSV from '../resources/icons/csv-document-svgrepo-com.svg';
import EXE from '../resources/icons/exe-document-svgrepo-com.svg';
import GDOC from '../resources/icons/gdoc-document-svgrepo-com.svg';
import IMG from '../resources/icons/image-document-svgrepo-com.svg';
import PDF from '../resources/icons/pdf-document-svgrepo-com.svg';
import PPT from '../resources/icons/ppt-document-svgrepo-com.svg';
import TXT from '../resources/icons/txt-document-svgrepo-com.svg';
import VID from '../resources/icons/video-document-svgrepo-com.svg';
import WORD from '../resources/icons/word-document-svgrepo-com.svg';
import XML from '../resources/icons/xml-document-svgrepo-com.svg';
import ZIP from '../resources/icons/zip-document-svgrepo-com.svg';

import "../styles/stylesheet_slide-show.css";

let slideIndex = 1;

// Create the slides.
function createSlides(urlImages) {
    let slideShow = document.getElementById("divSlideShowContainer");
    slideShow.innerHTML = "";
    // Create a loop for adding the fullsized images.
    for (let x = 0; x < urlImages.length; x++)
    {
        slideShow.insertAdjacentHTML("beforeend",
            "<div class=\"divSlides\">\n" +
            "   <div class=\"divNumbers\"></div>\n" +
            "   <img src=\"" + urlImages[x] + "\">" +
            "</div>");
    }

    function plusSlides(n) {
        showSlides(slideIndex += n);
    }

    function currentSlide(n) {
        showSlides(slideIndex = n);
    }
    // Add the navigation buttons.
    if (urlImages.length > 1) slideShow.insertAdjacentHTML("beforeend", "<a class=\"aPrev\" id='prev'>❮</a><a class=\"aNext\" id='next'>❯</a>");
    // Create the container for the columns.
    let rowContainer = document.createElement("div");
    rowContainer.className = "row";
    // Create a loop and add the column to the row.
    for (let i = 0; i < urlImages.length; i++) {
        let container = document.createElement("div");
        container.className = "column";
        let imgDemo = document.createElement("img");
        imgDemo.className = "imgDemo cursor";
        imgDemo.src = urlImages[i];
        imgDemo.style.width = "100%";
        imgDemo.onclick = () => currentSlide(i+1);
        container.append(imgDemo);
        rowContainer.insertAdjacentElement("beforeend", container);
    }
    // Add row container to the slideShow.
    slideShow.insertAdjacentElement("beforeend", rowContainer);
    // Show the slide.
    showSlides(slideIndex);

    if (urlImages.length > 1) {
        document.getElementById("prev").onclick = () => plusSlides(-1);
        document.getElementById("next").onclick = () => plusSlides(1);
    }
}

function showSlides(n) {
    let x;
    let slides = document.getElementsByClassName("divSlides");
    let dots = document.getElementsByClassName("imgDemo");
    if (n > slides.length) slideIndex = 1;
    if (n < 1) slideIndex = slides.length;
    for (x = 0; x < slides.length; x++) slides[x].style.display = "none";
    for (x = 0; x < dots.length; x++)  dots[x].className = dots[x].className.replace(" active", "");
    slides[slideIndex-1].style.display = "block";
    dots[slideIndex-1].className += " active";
}

const FileTypeIconComponent = ({data}) => {
    let fileTypeIcon;
    const
        fileSplit = data.split("."),
        fileType = fileSplit[fileSplit.length -1].toLowerCase();

    const
        nameSplit0 = data.split("/"),
        nameSplit1 = nameSplit0[nameSplit0.length-1],
        dashSplit0 = nameSplit1.split("-"),
        fileName   = dashSplit0[dashSplit0.length-1];

    switch (fileType) {
        case "gdoc":
            fileTypeIcon = GDOC;
            break;
        case "zip":
            fileTypeIcon = ZIP;
            break;
        case "csv":
            fileTypeIcon = CSV;
            break;
        case "pdf":
            fileTypeIcon = PDF;
            break;
        case "xlsx":
            fileTypeIcon = EXCEL;
            break;
        case "exe":
            fileTypeIcon = EXE;
            break;
        case "ppt":
            fileTypeIcon = PPT;
            break;
        case "txt":
            fileTypeIcon = TXT;
            break;
        case "mov":
        case "mp4":
            fileTypeIcon = VID;
            break;
        case "docx":
            fileTypeIcon = WORD;
            break;
        case "xml":
            fileTypeIcon = XML;
            break;
        case "png":
        case "jpg":
        case "svg":
            fileTypeIcon = IMG;
            break;
        default:
            fileTypeIcon = UNKNOWN;
    }

    return (
        <tr>
            <td style={{width: "25px"}}><img style={{height: "25px"}} src={fileTypeIcon} alt={""}/></td>
            <td><a href={data} download>{fileName}</a></td>
        </tr>
    )
}

const Pump = () => {
    const { category, pump } = useParams();
    // Request the pump form the API.
    const [data] = useFetch(`https://www.rs-pompen.eu/categories/${category}/${pump}`);

    useEffect(() => {
        if (data?.data.config) {
            createSlides(data?.data.config.images);
        }
    });


    return (
        <>
            <div className={"content"}>
                <div className={"container"}>
                    <div>
                        {
                            data && (
                                data.status === "OK" ? (
                                    <>
                                        <div className={"panel-row-flex black f2"} style={{flexWrap: "wrap"}}>
                                            <div>
                                                <h1>{pump}</h1>
                                                {/*<img src={data.data.config.images[0]} alt={""}/>*/}

                                                <div className="rowDivMachine divSlideShow divScaler2"
                                                     id="divSlideShowContainer"></div>
                                            </div>

                                            <div className={"description"}>
                                                <img src={data.data.logo} alt={""} style={{height: "100px", width: "auto"}}/>
                                                <p>
                                                    <strong>{data.data.config.info}</strong>
                                                    <br/>
                                                    <br/>
                                                    {data.data.config.description.split("\n").map(function(item, idx) {
                                                        return (
                                                            <span key={idx}>{item}<br/></span>
                                                        )
                                                    })
                                                    }
                                                </p>
                                                <a href={
                                                    `mailto:sales@rs-pompen.eu?subject=Aanvraag: ${data.data.name}&body=Geachte RS-pompen,%0D%0A%0D%0AGraag ontvang ik meer informatie betreffende onderstaand product.%0D%0A%0D%0AProduct: ${data.data.name}%0D%0A%0D%0A` +
                                                    `Neem contact met mij op via onderstaande gegevens.%0D%0A%0D%0A` +
                                                    `Mijn gegevens:%0D%0A` +
                                                    `Bedrijfsnaam:%0D%0A` +
                                                    `Voornaam:%0D%0A` +
                                                    `Achternaam:%0D%0A` +
                                                    `E-mail:%0D%0A` +
                                                    `Telefoon nummer:%0D%0A` +
                                                    `Land:%0D%0A` +
                                                    `Opmerkingen:%0D%0A`
                                                }
                                                   style={{padding: "0 20px 40x", minWidth: "170px"
                                                }} className={"button_contact div_center"}>Vraag een offerte aan</a>
                                            </div>
                                        </div>
                                        <div className={"description"}>
                                            <h1>Specificaties</h1>
                                            <table className={"specTable"}>
                                                <tbody>
                                                    {
                                                        <tr>
                                                            <td>Merk</td>
                                                            <td>{data.data.brand}</td>
                                                        </tr>
                                                    }
                                                    {data.data.config.specs && data.data.config.specs.map((specs) => (
                                                        <tr>
                                                            {
                                                                <td>{specs.split(": ")[0]}</td>
                                                            }
                                                            {
                                                                <td>{specs.split(": ")[1]}</td>
                                                            }
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            {
                                                data.data.config.downloads && (
                                                    <>
                                                        <h1>Downloads</h1>
                                                        <table className={"specTable"}>
                                                            <tbody>
                                                            {data.data.config.downloads.map((options) => (
                                                                <FileTypeIconComponent data={options}/>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    </>
                                                )
                                            }
                                            {
                                                data.data.config.options && (
                                                    <>
                                                        <h1>Opties</h1>
                                                        <table className={"specTable"}>
                                                            <tbody>
                                                            {data.data.config.options.map((options) => (
                                                                <tr>
                                                                    {
                                                                        <td>{options}</td>
                                                                    }
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    </>
                                                )
                                            }
                                            {
                                                data.data.config.technical && (
                                                    <>
                                                        <h1>Technische kenmerken</h1>
                                                        <table className={"specTable"}>
                                                            <tbody>
                                                            {data.data.config.technical.map((technical) => (
                                                                <tr>
                                                                    {
                                                                        <td>{technical}</td>
                                                                    }
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    </>
                                                )
                                            }
                                            {
                                                data.data.config.applications && (
                                                    <>
                                                        <h1>Toepassingen</h1>
                                                        <table className={"specTable"}>
                                                            <tbody>
                                                            {data.data.config.applications.map((applications) => (
                                                                <tr>
                                                                    {
                                                                        <td>{applications}</td>
                                                                    }
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </>
                                ) : <h1>Excuses, pomp '{pump}' kan niet gevonden worden!</h1>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
};

export default Pump;