const Service = () => {
    return (
        <>
            <div className={"content"}>
                <div className={"container"} style={{backgroundColor: "var(--main-background-color)", paddingTop: "10px", paddingBottom: "10px"}}>
                    <h1 style={{color: "white"}}>Service en pompen</h1>
                </div>
                <div className={"container container_blue"}>
                    <div>
                        <p style={{textAlign: "left"}}>
                            <strong>RS Pompen</strong> verleent service en onderhoud aan alle merken pompen, en is daardoor niet merk gebonden. Vanuit onze vestiging in Giesbeek worden de reparatie en revisie werkzaamheden uitgevoerd.
                        </p>
                        <p style={{textAlign: "left"}}>
                            Onze service engineers kunnen uw pompen voorzien van het juiste onderhoud op locatie of in onze werkplaats. Door te investeren in periodiek onderhoud, voorkom u problemen en storingen. Zo bespaard u op energie en gebruikskosten, en verlengt u de levensduur en kwaliteit van uw pomp installatie.
                        </p>
                        <p style={{textAlign: "left"}}>
                            Door het uitvoeren van een periodieke onderhoud, kunnen eventuele problemen eerder aan het licht komen. Mogelijke slijtage wordt opgemerkt voordat het tot schade leidt. De eventuele reparatie kan in overleg met u worden ingepland, zo word een ongeplande stilstand van uw pomp voorkomen.
                        </p>
                        <p style={{textAlign: "left"}}>
                            In de winter is het belangrijk de installatie te beschermen tegen vorst om mogelijke schade te voorkomen. RS Pompen kan de buitenbedrijfstelling van de installatie voor de winter en de opstart voor de zomer voor u verzorgen.
                        </p>
                        <h1 style={{textAlign: "left"}}>Maatwerk Service</h1>
                        <p style={{textAlign: "left"}}>
                            Om te voldoen aan uw wensen bieden wij u maatwerk service. Wij komen graag met u in gesprek om af te stemmen welke service behoefte uw installatie nodig heeft. Hierdoor krijgt u maatwerk service dat precies aansluit aan de wensen van u en uw pomp installatie.
                        </p>
                        <p style={{textAlign: "left"}}>
                            Heeft u een service aanvraag? Neem <a href="https://www.rs-pompen.eu/contact/">contact</a> op, bel <a href="tel:+31850608250">+31(0)85 06 08 250</a> of stuur een mail naar <a href="mailto:info@rs-pompen.eu">info@rs-pompen.eu</a>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Service;