import pompen_voor_de_industrie from "../resources/pompen_voor_de_industrie.jpg";
import pompen_voor_de_landbouw from "../resources/pompen_voor_de_landbouw.jpg";
import pompen_voor_de_utliteitsbouw from "../resources/pompen_voor_de_utliteitsbouw.jpg";
import pompen_voor_de_beregening from "../resources/pompen_voor_de_beregening.jpg";
import pompen_voor_grafische_industrie from "../resources/pompen_voor_grafische_industrie.jpg";
import pompen_voor_de_aannemerij from "../resources/pompen_voor_de_aannemerij.jpg";
import EN_AVANT from "../resources/EN_AVANT.jpg";
import verhuur from "../resources/verhuur.png";

const Toepassingne = () => {
    return (
        <>
            <div className={"content"}>
                <div className={"container"} style={{backgroundColor: "var(--main-background-color)", paddingTop: "10px", paddingBottom: "10px"}}>
                    <h1 style={{color: "white"}}>Toepassingen</h1>
                </div>
                <div className={"container"}>
                    <div>
                        <div className={"panel-row-flex black f2"} style={{flexWrap: "wrap"}}>
                            <div className={"center_vertical"}>
                                <img src={pompen_voor_de_industrie} alt={""}/>
                            </div>
                            <div>
                                <h2 style={{textAlign: "left"}}>Industrie en machinebouw</h2>
                                <p style={{textAlign: "left"}}>
                                    Op zoek naar een professionele partner voor uw pomp installaties.
                                    Door gebruik te maken van hoogwaardige kwaliteit producten, kunt u bedrijfszeker en energie zuiniger produceren.
                                </p>
                                <p style={{textAlign: "left"}}>
                                    Wij denken graag met u mee voor het meest effectieve en betrouwbare pompsysteem, het energieverbruik en de onderhoudskosten. Wij kunnen u ontzorgen door de montage, het onderhoud en de revisies voor u te verzorgen.
                                </p>
                                <p style={{textAlign: "left"}}>
                                    Wij maken gebruik van onze expertise, onze flexibiliteit, maatwerk en betrouwbaarheid om u een zo best mogelijke oplossing te bieden voor uw uitdaging.
                                </p>
                            </div>

                            <div className={"center_vertical"}>
                                <img src={pompen_voor_de_landbouw} alt={""}/>
                            </div>
                            <div>
                                <h2 style={{textAlign: "left"}}>Landbouw</h2>
                                <p style={{textAlign: "left"}}>
                                    In de agrarische sector is het van belang om efficiënt en snel te kunnen werken. U wilt geen productieverlies of droogte, dus een waarborging van kwaliteit en waterdruk is noodzakelijk. RS Pompen voorziet u van de het juiste advies en denken graag met u mee over klant specifieke oplossingen.
                                </p>
                                <p style={{textAlign: "left"}}>
                                    Naast het verpompen van water bent u ook voor het verpompen van afval water en mest bij ons aan het juiste adres. Met verschillende type pomp installaties, besturingen en materialen bieden wij klant specifieke oplossingen. Uiteraard leveren wij niet alleen de producten maar kunnen wij ook de installatie, het service en onderhoud voor u verzorgen.
                                </p>
                            </div>

                            <div className={"center_vertical"}>
                                <img src={pompen_voor_de_utliteitsbouw} alt={""}/>
                            </div>
                            <div>
                                <h2 style={{textAlign: "left"}}>Utiliteit</h2>
                                <p style={{textAlign: "left"}}>
                                    <strong>RS Pompen</strong> bied efficiënte, betrouwbare en duurzame pomp systemen aan voor gebouwinstallaties.
                                    Wij voorzien u van de het juiste advies en denken graag met u mee over klant specifieke oplossingen.
                                </p>
                                <p style={{textAlign: "left"}}>
                                    Met verschillende type pomp installaties, besturingen en materialen bieden wij klant het best passende oplossing.
                                    Uiteraard leveren wij niet alleen de producten maar kunnen wij ook de installatie, het service en onderhoud voor u verzorgen.
                                </p>
                            </div>

                            <div className={"center_vertical"}>
                                <img src={pompen_voor_de_beregening} alt={""}/>
                            </div>
                            <div>
                                <h2 style={{textAlign: "left"}}>
                                    Glasbouw , park en landbouw beregening
                                </h2>
                                <p style={{textAlign: "left"}}>
                                    Gewassen groeien minder hard bij aanhoudende droogte en warmte. Voor beregening is dan een ook een watervoorziening met een hoge capaciteit gewenst. U wilt verzekerd zijn van een optimaal werkende installatie. RS Pompen levert maatwerk in bronpompen, diesel of elektrisch aangedreven hoge druk pompen en complete pomp installaties.
                                </p>
                                <p style={{textAlign: "left"}}>
                                    Van ontwerp tot levering en montage tot het onderhoud kan RS Pompen voor u verzorgen. Wij komen graag met u in gesprek om uw wensen te bespreken.
                                </p>
                            </div>

                            <div className={"center_vertical"}>
                                <img src={pompen_voor_grafische_industrie} alt={""}/>
                            </div>
                            <div>
                                <h2 style={{textAlign: "left"}}>
                                    Grafische en papier industrie
                                </h2>
                                <p style={{textAlign: "left"}}>
                                    <strong>RS Pompen</strong> levert pompen en haar service diensten in de grafische papier industrie. Veel pompen spelen een cruciale rol in het drukproces bij rotatiepersen. Het is van groot belang dat de bakwalsen op de juiste temperatuur blijven, en niet teveel temperatuur schommelingen in het proces ontstaan. Hierdoor blijft (UV) inkt in de juiste viscositeit.
                                </p>
                                <p style={{textAlign: "left"}}>
                                    Ook in de papier industrie spelen pompen een grote rol in het productie proces. Denk aan transport pompen, voor het verpompen van proceswater, afvalwater en of pulp. Ongewenste stilstand in het productie proces willen we graag voorkomen. Door tijdig onderhoud uit te voeren, kunt u onnodige downtime verkleinen en waarborgt u de bedrijfszekerheid van uw machines.
                                </p>
                            </div>

                            <div className={"center_vertical"}>
                                <img src={pompen_voor_de_aannemerij} alt={""}/>
                            </div>
                            <div>
                                <h2 style={{textAlign: "left"}}>
                                    Bouwnijverheid
                                </h2>
                                <p style={{textAlign: "left"}}>
                                    <strong>RS Pompen</strong> heeft ervaring in het leveren van pompen en appendages voor alle denkbare toepassingen in de grond-, weg- en waterbouw. Ook verhuur bedrijven en bronbemalers zijn bij ons op het juiste adres voor advies en voor onze producten. Wij denken graag met u mee voor een de juiste (zware) toepassing, zodat wij u een goede duurzame oplossing kunnen aanbieden.
                                </p>
                                <p style={{textAlign: "left"}}>
                                    Ook in de papier industrie spelen pompen een grote rol in het productie proces. Denk aan transport pompen, voor het verpompen van proceswater, afvalwater en of pulp. Ongewenste stilstand in het productie proces willen we graag voorkomen. Door tijdig onderhoud uit te voeren, kunt u onnodige downtime verkleinen en waarborgt u de bedrijfszekerheid van uw machines.
                                </p>
                            </div>

                            <div className={"center_vertical"}>
                                <img src={EN_AVANT} alt={""}/>
                            </div>
                            <div>
                                <h2 style={{textAlign: "left"}}>
                                    Scheepsvaart
                                </h2>
                                <p style={{textAlign: "left"}}>
                                    <strong>RS Pompen</strong> is gespecialiseerd in pompen voor de scheepsvaart.
                                    Door onze kennis en ervaring in deze branche kunnen wij goed adviseren op het gebied van pompen, ballast pompen, Membraan pompen, hydrofoor installaties en afsluiters. Wij denken graag met de klant mee naar oplossingen. En ontzorgen u graag zodat u met uw dagelijkse gang van zaken kunt bezig houden.
                                </p>
                                <p style={{textAlign: "left"}}>
                                    Naast ons advies en onze producten, kunt u ook bij ons ook terecht voor:
                                </p>
                                <ul style={{textAlign: "left"}}>
                                    <li>Nieuwbouw</li>
                                    <li>Onderhoud</li>
                                    <li>Reparatie</li>
                                    <li>Revisie</li>
                                    <li>Service</li>
                                </ul>
                            </div>

                            <div className={"center_vertical"}>
                                <img src={verhuur} alt={""}/>
                            </div>
                            <div>
                                <h2 style={{textAlign: "left"}}>
                                    Verhuur markt
                                </h2>
                                <p style={{textAlign: "left"}}>
                                    <strong>RS Pompen</strong> heeft meerdere pompen in het assortiment geschikt voor de verhuurmarkt. Elke verhuur klus vraagt zijn eigen unieke oplossing. Zo vraagt het leeg pompen van een zwembad tot de laatste millimeter om een andere pomp dan een pomp voor het leeghouden van een bouwput. Graag denken wij met u mee welke pomp geschikt is voor uw verhuur klus. Uitgangspunten zijn; duurzaam, gebruiksvriendelijk en robuust. Ook voor op maat gemaakt pomp oplossingen bent u bij ons aan het juiste adres. Met onze eigen 3D engineering ontwerpen wij de oplossing naar uw specifieke wensen.
                                </p>
                                <p style={{textAlign: "left"}}>
                                    Naast ons advies en onze producten, kunt u ook bij ons ook terecht voor:
                                </p>
                                <ul style={{textAlign: "left"}}>
                                    <li>Drainage dompelpompen</li>
                                    <li>Vlakzuigpompen</li>
                                    <li>Slijtvaste dompelpompen</li>
                                    <li>Verdringer pompen</li>
                                    <li>Diesel of elektrisch aangedreven zelfaanzuigende pompen</li>
                                    <li>Diesel of elektrisch aangedreven vuilwater pompen</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Toepassingne;