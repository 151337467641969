import '../styles/footer.css';
import '../styles/default.css';
const Footer = () => {
    return (
        <footer>
            <p>
                <b>RS pompen</b><br/>
                Onderdeel van RS-machinebouw<br/>
                <br/>
                Uitmaat 10b<br/>
                6987 ER  Giesbeek<br/>
                +31 (0)85 06 08 250<br/>
                <a href="mailto:info@rs-pompen.eu">info@rs-pompen.eu</a>
                <br/>
            </p>
        </footer>
    )
};

export default Footer;