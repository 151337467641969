import '../styles/header.css';
import '../styles/default.css';
import logo from '../resources/logo_rs_pompen.png';
import headerImg from '../resources/rs_pompen_header.jpg';
import burger from '../resources/icon_burger.svg';
import {Link} from "react-router-dom";
const Header = () => {
    return (
        <header>
            <div>
                <div>
                    <Link to={"/"}>
                        <img className={"headerLogo"} src={logo} alt={""}/>
                    </Link>
                    <img className={"burgerIcon"} src={burger} alt={""}/>
                </div>
                <div className={"headerMenu"} style={{maxWidth: "none"}}>
                    <ul>
                        <li><Link to={"/"}>Home</Link></li>
                        <li><Link to={"/toepassingen"}>Toepassingen</Link></li>
                        <li><Link to={"/pompen"}>Pompen</Link></li>
                        <li><Link to={"/service"}>Service</Link></li>
                        <li><Link to={"/engineering"}>Engineering</Link></li>
                        <li><Link to={"/contact"}>Contact</Link></li>
                    </ul>
                </div>
            </div>
            <div className={"headerImageContainer"}>
                <div className={"headerInfoContainer"}>
                    <div style={{width: "96%", maxWidth: "var(--main-max-width)", margin: "0 auto 0 auto"}}>
                        <div className={"headerInfo"}><strong>RS-POMPEN</strong> IS UW SPECIALIST IN LEVERING EN ONDERHOUD VAN POMPEN!
                            <Link to={"/contact"} className={"button_contact"}>Neem contact met ons op</Link>
                        </div>
                    </div>
                </div>
                <img className={"headerImage"} src={headerImg} alt={""}/>
            </div>
        </header>
    )
};

export default Header;